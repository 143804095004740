import React from 'react';

import Layout from '../components/Layout';
import Seo from './../components/Seo';
import aboutStyles from './../styles/about.module.scss';
import ben from './../assets/images/ben.png';
import mike from './../assets/images/mike.png';

const About = () => {
  return (
    <Layout>
      <Seo title={'Blog | TechPods'}/>
      <main className={aboutStyles.container}>
        <section className={aboutStyles.aboutUs}>
          <div className={aboutStyles.aboutUsInfo}>
            <h1 className="primary-title">About us</h1>
            <p className="important-text">
              The TechPods model is an IT staff augmentation model, 
              meaning we hire IT development professionals with very 
              specific technology skill sets, exclusively for your 
              business. We don’t have internal developers sitting 
              on a bench nor do we use subcontractors.
            </p>
            <svg viewBox="0 0 346 320" width="346" height="320" fill="yellow" xmlns="http://www.w3.org/2000/svg">
              <circle cx="14" cy="14" r="0.6" fill="#878787" />
              <circle cx="28" cy="14" r="0.6" fill="#878787" />
              <circle cx="42" cy="14" r="0.6" fill="#878787" />
              <circle cx="56" cy="14" r="0.6" fill="#878787" />
              <circle cx="70" cy="14" r="0.6" fill="#878787" />
              <circle cx="84" cy="14" r="0.6" fill="#878787" />
              <circle cx="98" cy="14" r="0.6" fill="#878787" />
              <circle cx="112" cy="14" r="0.6" fill="#878787" />
              <circle cx="126" cy="14" r="0.6" fill="#878787" />
              <circle cx="140" cy="14" r="0.6" fill="#878787" />
              <circle cx="154" cy="14" r="0.6" fill="#878787" />
              <circle cx="168" cy="14" r="0.6" fill="#878787" />
              <circle cx="182" cy="14" r="0.6" fill="#878787" />
              <circle cx="196" cy="14" r="0.6" fill="#878787" />
              <circle cx="210" cy="14" r="0.6" fill="#878787" />
              <circle cx="224" cy="14" r="0.6" fill="#878787" />
              <circle cx="238" cy="14" r="0.6" fill="#878787" />
              <circle cx="252" cy="14" r="0.6" fill="#878787" />
              <circle cx="266" cy="14" r="0.6" fill="#878787" />
              <circle cx="280" cy="14" r="0.6" fill="#878787" />
              <circle cx="294" cy="14" r="0.6" fill="#878787" />
              <circle cx="308" cy="14" r="0.6" fill="#878787" />
              <circle cx="322" cy="14" r="0.6" fill="#878787" />
              <circle cx="14" cy="28" r="0.6" fill="#878787" />
              <circle cx="28" cy="28" r="0.6" fill="#878787" />
              <circle cx="42" cy="28" r="0.6" fill="#878787" />
              <circle cx="56" cy="28" r="0.6" fill="#878787" />
              <circle cx="70" cy="28" r="0.6" fill="#878787" />
              <circle cx="84" cy="28" r="0.6" fill="#878787" />
              <circle cx="98" cy="28" r="0.6" fill="#878787" />
              <circle cx="112" cy="28" r="0.6" fill="#878787" />
              <circle cx="126" cy="28" r="0.6" fill="#878787" />
              <circle cx="140" cy="28" r="0.6" fill="#878787" />
              <circle cx="154" cy="28" r="0.6" fill="#878787" />
              <circle cx="168" cy="28" r="0.6" fill="#878787" />
              <circle cx="182" cy="28" r="0.6" fill="#878787" />
              <circle cx="196" cy="28" r="0.6" fill="#878787" />
              <circle cx="210" cy="28" r="0.6" fill="#878787" />
              <circle cx="224" cy="28" r="0.6" fill="#878787" />
              <circle cx="238" cy="28" r="0.6" fill="#878787" />
              <circle cx="252" cy="28" r="0.6" fill="#878787" />
              <circle cx="266" cy="28" r="0.6" fill="#878787" />
              <circle cx="280" cy="28" r="0.6" fill="#878787" />
              <circle cx="294" cy="28" r="0.6" fill="#878787" />
              <circle cx="308" cy="28" r="0.6" fill="#878787" />
              <circle cx="322" cy="28" r="0.6" fill="#878787" />
              <circle cx="14" cy="42" r="0.6" fill="#878787" />
              <circle cx="28" cy="42" r="0.6" fill="#878787" />
              <circle cx="42" cy="42" r="0.6" fill="#878787" />
              <circle cx="56" cy="42" r="0.6" fill="#878787" />
              <circle cx="70" cy="42" r="0.6" fill="#878787" />
              <circle cx="84" cy="42" r="0.6" fill="#878787" />
              <circle cx="98" cy="42" r="0.6" fill="#878787" />
              <circle cx="112" cy="42" r="0.6" fill="#878787" />
              <circle cx="126" cy="42" r="0.6" fill="#878787" />
              <circle cx="140" cy="42" r="0.6" fill="#878787" />
              <circle cx="154" cy="42" r="0.6" fill="#878787" />
              <circle cx="168" cy="42" r="0.6" fill="#878787" />
              <circle cx="182" cy="42" r="0.6" fill="#878787" />
              <circle cx="196" cy="42" r="0.6" fill="#878787" />
              <circle cx="210" cy="42" r="0.6" fill="#878787" />
              <circle cx="224" cy="42" r="0.6" fill="#878787" />
              <circle cx="238" cy="42" r="0.6" fill="#878787" />
              <circle cx="252" cy="42" r="0.6" fill="#878787" />
              <circle cx="266" cy="42" r="0.6" fill="#878787" />
              <circle cx="280" cy="42" r="0.6" fill="#878787" />
              <circle cx="294" cy="42" r="0.6" fill="#878787" />
              <circle cx="308" cy="42" r="0.6" fill="#878787" />
              <circle cx="322" cy="42" r="0.6" fill="#878787" />
              <circle cx="14" cy="56" r="0.6" fill="#878787" />
              <circle cx="28" cy="56" r="0.6" fill="#878787" />
              <circle cx="42" cy="56" r="0.6" fill="#878787" />
              <circle cx="56" cy="56" r="0.6" fill="#878787" />
              <circle cx="70" cy="56" r="0.6" fill="#878787" />
              <circle cx="84" cy="56" r="0.6" fill="#878787" />
              <circle cx="98" cy="56" r="0.6" fill="#878787" />
              <circle cx="112" cy="56" r="0.6" fill="#878787" />
              <circle cx="126" cy="56" r="0.6" fill="#878787" />
              <circle cx="140" cy="56" r="0.6" fill="#878787" />
              <circle cx="154" cy="56" r="0.6" fill="#878787" />
              <circle cx="168" cy="56" r="0.6" fill="#878787" />
              <circle cx="182" cy="56" r="0.6" fill="#878787" />
              <circle cx="196" cy="56" r="0.6" fill="#878787" />
              <circle cx="210" cy="56" r="0.6" fill="#878787" />
              <circle cx="224" cy="56" r="0.6" fill="#878787" />
              <circle cx="238" cy="56" r="0.6" fill="#878787" />
              <circle cx="252" cy="56" r="0.6" fill="#878787" />
              <circle cx="266" cy="56" r="0.6" fill="#878787" />
              <circle cx="280" cy="56" r="0.6" fill="#878787" />
              <circle cx="294" cy="56" r="0.6" fill="#878787" />
              <circle cx="308" cy="56" r="0.6" fill="#878787" />
              <circle cx="322" cy="56" r="0.6" fill="#878787" />
              <circle cx="14" cy="70" r="0.6" fill="#878787" />
              <circle cx="28" cy="70" r="0.6" fill="#878787" />
              <circle cx="42" cy="70" r="0.6" fill="#878787" />
              <circle cx="56" cy="70" r="0.6" fill="#878787" />
              <circle cx="70" cy="70" r="0.6" fill="#878787" />
              <circle cx="84" cy="70" r="0.6" fill="#878787" />
              <circle cx="98" cy="70" r="0.6" fill="#878787" />
              <circle cx="112" cy="70" r="0.6" fill="#878787" />
              <circle cx="126" cy="70" r="0.6" fill="#878787" />
              <circle cx="140" cy="70" r="0.6" fill="#878787" />
              <circle cx="154" cy="70" r="0.6" fill="#878787" />
              <circle cx="168" cy="70" r="0.6" fill="#878787" />
              <circle cx="182" cy="70" r="0.6" fill="#878787" />
              <circle cx="196" cy="70" r="0.6" fill="#878787" />
              <circle cx="210" cy="70" r="0.6" fill="#878787" />
              <circle cx="224" cy="70" r="0.6" fill="#878787" />
              <circle cx="238" cy="70" r="0.6" fill="#878787" />
              <circle cx="252" cy="70" r="0.6" fill="#878787" />
              <circle cx="266" cy="70" r="0.6" fill="#878787" />
              <circle cx="280" cy="70" r="0.6" fill="#878787" />
              <circle cx="294" cy="70" r="0.6" fill="#878787" />
              <circle cx="308" cy="70" r="0.6" fill="#878787" />
              <circle cx="322" cy="70" r="0.6" fill="#878787" />
              <circle cx="14" cy="84" r="0.6" fill="#878787" />
              <circle cx="28" cy="84" r="0.6" fill="#878787" />
              <circle cx="42" cy="84" r="0.6" fill="#878787" />
              <circle cx="56" cy="84" r="0.6" fill="#878787" />
              <circle cx="70" cy="84" r="0.6" fill="#878787" />
              <circle cx="84" cy="84" r="0.6" fill="#878787" />
              <circle cx="98" cy="84" r="0.6" fill="#878787" />
              <circle cx="112" cy="84" r="0.6" fill="#878787" />
              <circle cx="126" cy="84" r="0.6" fill="#878787" />
              <circle cx="140" cy="84" r="0.6" fill="#878787" />
              <circle cx="154" cy="84" r="0.6" fill="#878787" />
              <circle cx="168" cy="84" r="0.6" fill="#878787" />
              <circle cx="182" cy="84" r="0.6" fill="#878787" />
              <circle cx="196" cy="84" r="0.6" fill="#878787" />
              <circle cx="210" cy="84" r="0.6" fill="#878787" />
              <circle cx="224" cy="84" r="0.6" fill="#878787" />
              <circle cx="238" cy="84" r="0.6" fill="#878787" />
              <circle cx="252" cy="84" r="0.6" fill="#878787" />
              <circle cx="266" cy="84" r="0.6" fill="#878787" />
              <circle cx="280" cy="84" r="0.6" fill="#878787" />
              <circle cx="294" cy="84" r="0.6" fill="#878787" />
              <circle cx="308" cy="84" r="0.6" fill="#878787" />
              <circle cx="322" cy="84" r="0.6" fill="#878787" />
              <circle cx="14" cy="98" r="0.6" fill="#878787" />
              <circle cx="28" cy="98" r="0.6" fill="#878787" />
              <circle cx="42" cy="98" r="0.6" fill="#878787" />
              <circle cx="56" cy="98" r="0.6" fill="#878787" />
              <circle cx="70" cy="98" r="0.6" fill="#878787" />
              <circle cx="84" cy="98" r="0.6" fill="#878787" />
              <circle cx="98" cy="98" r="0.6" fill="#878787" />
              <circle cx="112" cy="98" r="0.6" fill="#878787" />
              <circle cx="126" cy="98" r="0.6" fill="#878787" />
              <circle cx="140" cy="98" r="0.6" fill="#878787" />
              <circle cx="154" cy="98" r="0.6" fill="#878787" />
              <circle cx="168" cy="98" r="0.6" fill="#878787" />
              <circle cx="182" cy="98" r="0.6" fill="#878787" />
              <circle cx="196" cy="98" r="0.6" fill="#878787" />
              <circle cx="210" cy="98" r="0.6" fill="#878787" />
              <circle cx="224" cy="98" r="0.6" fill="#878787" />
              <circle cx="238" cy="98" r="0.6" fill="#878787" />
              <circle cx="252" cy="98" r="0.6" fill="#878787" />
              <circle cx="266" cy="98" r="0.6" fill="#878787" />
              <circle cx="280" cy="98" r="0.6" fill="#878787" />
              <circle cx="294" cy="98" r="0.6" fill="#878787" />
              <circle cx="308" cy="98" r="0.6" fill="#878787" />
              <circle cx="322" cy="98" r="0.6" fill="#878787" />
              <circle cx="14" cy="112" r="0.6" fill="#878787" />
              <circle cx="28" cy="112" r="0.6" fill="#878787" />
              <circle cx="42" cy="112" r="0.6" fill="#878787" />
              <circle cx="56" cy="112" r="0.6" fill="#878787" />
              <circle cx="70" cy="112" r="0.6" fill="#878787" />
              <circle cx="84" cy="112" r="0.6" fill="#878787" />
              <circle cx="98" cy="112" r="0.6" fill="#878787" />
              <circle cx="112" cy="112" r="0.6" fill="#878787" />
              <circle cx="126" cy="112" r="0.6" fill="#878787" />
              <circle cx="140" cy="112" r="0.6" fill="#878787" />
              <circle cx="154" cy="112" r="0.6" fill="#878787" />
              <circle cx="168" cy="112" r="0.6" fill="#878787" />
              <circle cx="182" cy="112" r="0.6" fill="#878787" />
              <circle cx="196" cy="112" r="0.6" fill="#878787" />
              <circle cx="210" cy="112" r="0.6" fill="#878787" />
              <circle cx="224" cy="112" r="0.6" fill="#878787" />
              <circle cx="238" cy="112" r="0.6" fill="#878787" />
              <circle cx="252" cy="112" r="0.6" fill="#878787" />
              <circle cx="266" cy="112" r="0.6" fill="#878787" />
              <circle cx="280" cy="112" r="0.6" fill="#878787" />
              <circle cx="294" cy="112" r="0.6" fill="#878787" />
              <circle cx="308" cy="112" r="0.6" fill="#878787" />
              <circle cx="322" cy="112" r="0.6" fill="#878787" />
              <circle cx="14" cy="126" r="0.6" fill="#878787" />
              <circle cx="28" cy="126" r="0.6" fill="#878787" />
              <circle cx="42" cy="126" r="0.6" fill="#878787" />
              <circle cx="56" cy="126" r="0.6" fill="#878787" />
              <circle cx="70" cy="126" r="0.6" fill="#878787" />
              <circle cx="84" cy="126" r="0.6" fill="#878787" />
              <circle cx="98" cy="126" r="0.6" fill="#878787" />
              <circle cx="112" cy="126" r="0.6" fill="#878787" />
              <circle cx="126" cy="126" r="0.6" fill="#878787" />
              <circle cx="140" cy="126" r="0.6" fill="#878787" />
              <circle cx="154" cy="126" r="0.6" fill="#878787" />
              <circle cx="168" cy="126" r="0.6" fill="#878787" />
              <circle cx="182" cy="126" r="0.6" fill="#878787" />
              <circle cx="196" cy="126" r="0.6" fill="#878787" />
              <circle cx="210" cy="126" r="0.6" fill="#878787" />
              <circle cx="224" cy="126" r="0.6" fill="#878787" />
              <circle cx="238" cy="126" r="0.6" fill="#878787" />
              <circle cx="252" cy="126" r="0.6" fill="#878787" />
              <circle cx="266" cy="126" r="0.6" fill="#878787" />
              <circle cx="280" cy="126" r="0.6" fill="#878787" />
              <circle cx="294" cy="126" r="0.6" fill="#878787" />
              <circle cx="308" cy="126" r="0.6" fill="#878787" />
              <circle cx="322" cy="126" r="0.6" fill="#878787" />
              <circle cx="14" cy="140" r="0.6" fill="#878787" />
              <circle cx="28" cy="140" r="0.6" fill="#878787" />
              <circle cx="42" cy="140" r="0.6" fill="#878787" />
              <circle cx="56" cy="140" r="0.6" fill="#878787" />
              <circle cx="70" cy="140" r="0.6" fill="#878787" />
              <circle cx="84" cy="140" r="0.6" fill="#878787" />
              <circle cx="98" cy="140" r="0.6" fill="#878787" />
              <circle cx="112" cy="140" r="0.6" fill="#878787" />
              <circle cx="126" cy="140" r="0.6" fill="#878787" />
              <circle cx="140" cy="140" r="0.6" fill="#878787" />
              <circle cx="154" cy="140" r="0.6" fill="#878787" />
              <circle cx="168" cy="140" r="0.6" fill="#878787" />
              <circle cx="182" cy="140" r="0.6" fill="#878787" />
              <circle cx="196" cy="140" r="0.6" fill="#878787" />
              <circle cx="210" cy="140" r="0.6" fill="#878787" />
              <circle cx="224" cy="140" r="0.6" fill="#878787" />
              <circle cx="238" cy="140" r="0.6" fill="#878787" />
              <circle cx="252" cy="140" r="0.6" fill="#878787" />
              <circle cx="266" cy="140" r="0.6" fill="#878787" />
              <circle cx="280" cy="140" r="0.6" fill="#878787" />
              <circle cx="294" cy="140" r="0.6" fill="#878787" />
              <circle cx="308" cy="140" r="0.6" fill="#878787" />
              <circle cx="322" cy="140" r="0.6" fill="#878787" />
              <circle cx="14" cy="154" r="0.6" fill="#878787" />
              <circle cx="28" cy="154" r="0.6" fill="#878787" />
              <circle cx="42" cy="154" r="0.6" fill="#878787" />
              <circle cx="56" cy="154" r="0.6" fill="#878787" />
              <circle cx="70" cy="154" r="0.6" fill="#878787" />
              <circle cx="84" cy="154" r="0.6" fill="#878787" />
              <circle cx="98" cy="154" r="0.6" fill="#878787" />
              <circle cx="112" cy="154" r="0.6" fill="#878787" />
              <circle cx="126" cy="154" r="0.6" fill="#878787" />
              <circle cx="140" cy="154" r="0.6" fill="#878787" />
              <circle cx="154" cy="154" r="0.6" fill="#878787" />
              <circle cx="168" cy="154" r="0.6" fill="#878787" />
              <circle cx="182" cy="154" r="0.6" fill="#878787" />
              <circle cx="196" cy="154" r="0.6" fill="#878787" />
              <circle cx="210" cy="154" r="0.6" fill="#878787" />
              <circle cx="224" cy="154" r="0.6" fill="#878787" />
              <circle cx="238" cy="154" r="0.6" fill="#878787" />
              <circle cx="252" cy="154" r="0.6" fill="#878787" />
              <circle cx="266" cy="154" r="0.6" fill="#878787" />
              <circle cx="280" cy="154" r="0.6" fill="#878787" />
              <circle cx="294" cy="154" r="0.6" fill="#878787" />
              <circle cx="308" cy="154" r="0.6" fill="#878787" />
              <circle cx="322" cy="154" r="0.6" fill="#878787" />
              <circle cx="14" cy="168" r="0.6" fill="#878787" />
              <circle cx="28" cy="168" r="0.6" fill="#878787" />
              <circle cx="42" cy="168" r="0.6" fill="#878787" />
              <circle cx="56" cy="168" r="0.6" fill="#878787" />
              <circle cx="70" cy="168" r="0.6" fill="#878787" />
              <circle cx="84" cy="168" r="0.6" fill="#878787" />
              <circle cx="98" cy="168" r="0.6" fill="#878787" />
              <circle cx="112" cy="168" r="0.6" fill="#878787" />
              <circle cx="126" cy="168" r="0.6" fill="#878787" />
              <circle cx="140" cy="168" r="0.6" fill="#878787" />
              <circle cx="154" cy="168" r="0.6" fill="#878787" />
              <circle cx="168" cy="168" r="0.6" fill="#878787" />
              <circle cx="182" cy="168" r="0.6" fill="#878787" />
              <circle cx="196" cy="168" r="0.6" fill="#878787" />
              <circle cx="210" cy="168" r="0.6" fill="#878787" />
              <circle cx="224" cy="168" r="0.6" fill="#878787" />
              <circle cx="238" cy="168" r="0.6" fill="#878787" />
              <circle cx="252" cy="168" r="0.6" fill="#878787" />
              <circle cx="266" cy="168" r="0.6" fill="#878787" />
              <circle cx="280" cy="168" r="0.6" fill="#878787" />
              <circle cx="294" cy="168" r="0.6" fill="#878787" />
              <circle cx="308" cy="168" r="0.6" fill="#878787" />
              <circle cx="322" cy="168" r="0.6" fill="#878787" />
              <circle cx="14" cy="182" r="0.6" fill="#878787" />
              <circle cx="28" cy="182" r="0.6" fill="#878787" />
              <circle cx="42" cy="182" r="0.6" fill="#878787" />
              <circle cx="56" cy="182" r="0.6" fill="#878787" />
              <circle cx="70" cy="182" r="0.6" fill="#878787" />
              <circle cx="84" cy="182" r="0.6" fill="#878787" />
              <circle cx="98" cy="182" r="0.6" fill="#878787" />
              <circle cx="112" cy="182" r="0.6" fill="#878787" />
              <circle cx="126" cy="182" r="0.6" fill="#878787" />
              <circle cx="140" cy="182" r="0.6" fill="#878787" />
              <circle cx="154" cy="182" r="0.6" fill="#878787" />
              <circle cx="168" cy="182" r="0.6" fill="#878787" />
              <circle cx="182" cy="182" r="0.6" fill="#878787" />
              <circle cx="196" cy="182" r="0.6" fill="#878787" />
              <circle cx="210" cy="182" r="0.6" fill="#878787" />
              <circle cx="224" cy="182" r="0.6" fill="#878787" />
              <circle cx="238" cy="182" r="0.6" fill="#878787" />
              <circle cx="252" cy="182" r="0.6" fill="#878787" />
              <circle cx="266" cy="182" r="0.6" fill="#878787" />
              <circle cx="280" cy="182" r="0.6" fill="#878787" />
              <circle cx="294" cy="182" r="0.6" fill="#878787" />
              <circle cx="308" cy="182" r="0.6" fill="#878787" />
              <circle cx="322" cy="182" r="0.6" fill="#878787" />
              <circle cx="14" cy="196" r="0.6" fill="#878787" />
              <circle cx="28" cy="196" r="0.6" fill="#878787" />
              <circle cx="42" cy="196" r="0.6" fill="#878787" />
              <circle cx="56" cy="196" r="0.6" fill="#878787" />
              <circle cx="70" cy="196" r="0.6" fill="#878787" />
              <circle cx="84" cy="196" r="0.6" fill="#878787" />
              <circle cx="98" cy="196" r="0.6" fill="#878787" />
              <circle cx="112" cy="196" r="0.6" fill="#878787" />
              <circle cx="126" cy="196" r="0.6" fill="#878787" />
              <circle cx="140" cy="196" r="0.6" fill="#878787" />
              <circle cx="154" cy="196" r="0.6" fill="#878787" />
              <circle cx="168" cy="196" r="0.6" fill="#878787" />
              <circle cx="182" cy="196" r="0.6" fill="#878787" />
              <circle cx="196" cy="196" r="0.6" fill="#878787" />
              <circle cx="210" cy="196" r="0.6" fill="#878787" />
              <circle cx="224" cy="196" r="0.6" fill="#878787" />
              <circle cx="238" cy="196" r="0.6" fill="#878787" />
              <circle cx="252" cy="196" r="0.6" fill="#878787" />
              <circle cx="266" cy="196" r="0.6" fill="#878787" />
              <circle cx="280" cy="196" r="0.6" fill="#878787" />
              <circle cx="294" cy="196" r="0.6" fill="#878787" />
              <circle cx="308" cy="196" r="0.6" fill="#878787" />
              <circle cx="322" cy="196" r="0.6" fill="#878787" />
              <circle cx="14" cy="210" r="0.6" fill="#878787" />
              <circle cx="28" cy="210" r="0.6" fill="#878787" />
              <circle cx="42" cy="210" r="0.6" fill="#878787" />
              <circle cx="56" cy="210" r="0.6" fill="#878787" />
              <circle cx="70" cy="210" r="0.6" fill="#878787" />
              <circle cx="84" cy="210" r="0.6" fill="#878787" />
              <circle cx="98" cy="210" r="0.6" fill="#878787" />
              <circle cx="112" cy="210" r="0.6" fill="#878787" />
              <circle cx="126" cy="210" r="0.6" fill="#878787" />
              <circle cx="140" cy="210" r="0.6" fill="#878787" />
              <circle cx="154" cy="210" r="0.6" fill="#878787" />
              <circle cx="168" cy="210" r="0.6" fill="#878787" />
              <circle cx="182" cy="210" r="0.6" fill="#878787" />
              <circle cx="196" cy="210" r="0.6" fill="#878787" />
              <circle cx="210" cy="210" r="0.6" fill="#878787" />
              <circle cx="224" cy="210" r="0.6" fill="#878787" />
              <circle cx="238" cy="210" r="0.6" fill="#878787" />
              <circle cx="252" cy="210" r="0.6" fill="#878787" />
              <circle cx="266" cy="210" r="0.6" fill="#878787" />
              <circle cx="280" cy="210" r="0.6" fill="#878787" />
              <circle cx="294" cy="210" r="0.6" fill="#878787" />
              <circle cx="308" cy="210" r="0.6" fill="#878787" />
              <circle cx="322" cy="210" r="0.6" fill="#878787" />
              <circle cx="14" cy="224" r="0.6" fill="#878787" />
              <circle cx="28" cy="224" r="0.6" fill="#878787" />
              <circle cx="42" cy="224" r="0.6" fill="#878787" />
              <circle cx="56" cy="224" r="0.6" fill="#878787" />
              <circle cx="70" cy="224" r="0.6" fill="#878787" />
              <circle cx="84" cy="224" r="0.6" fill="#878787" />
              <circle cx="98" cy="224" r="0.6" fill="#878787" />
              <circle cx="112" cy="224" r="0.6" fill="#878787" />
              <circle cx="126" cy="224" r="0.6" fill="#878787" />
              <circle cx="140" cy="224" r="0.6" fill="#878787" />
              <circle cx="154" cy="224" r="0.6" fill="#878787" />
              <circle cx="168" cy="224" r="0.6" fill="#878787" />
              <circle cx="182" cy="224" r="0.6" fill="#878787" />
              <circle cx="196" cy="224" r="0.6" fill="#878787" />
              <circle cx="210" cy="224" r="0.6" fill="#878787" />
              <circle cx="224" cy="224" r="0.6" fill="#878787" />
              <circle cx="238" cy="224" r="0.6" fill="#878787" />
              <circle cx="252" cy="224" r="0.6" fill="#878787" />
              <circle cx="266" cy="224" r="0.6" fill="#878787" />
              <circle cx="280" cy="224" r="0.6" fill="#878787" />
              <circle cx="294" cy="224" r="0.6" fill="#878787" />
              <circle cx="308" cy="224" r="0.6" fill="#878787" />
              <circle cx="322" cy="224" r="0.6" fill="#878787" />
              <circle cx="14" cy="238" r="0.6" fill="#878787" />
              <circle cx="28" cy="238" r="0.6" fill="#878787" />
              <circle cx="42" cy="238" r="0.6" fill="#878787" />
              <circle cx="56" cy="238" r="0.6" fill="#878787" />
              <circle cx="70" cy="238" r="0.6" fill="#878787" />
              <circle cx="84" cy="238" r="0.6" fill="#878787" />
              <circle cx="98" cy="238" r="0.6" fill="#878787" />
              <circle cx="112" cy="238" r="0.6" fill="#878787" />
              <circle cx="126" cy="238" r="0.6" fill="#878787" />
              <circle cx="140" cy="238" r="0.6" fill="#878787" />
              <circle cx="154" cy="238" r="0.6" fill="#878787" />
              <circle cx="168" cy="238" r="0.6" fill="#878787" />
              <circle cx="182" cy="238" r="0.6" fill="#878787" />
              <circle cx="196" cy="238" r="0.6" fill="#878787" />
              <circle cx="210" cy="238" r="0.6" fill="#878787" />
              <circle cx="224" cy="238" r="0.6" fill="#878787" />
              <circle cx="238" cy="238" r="0.6" fill="#878787" />
              <circle cx="252" cy="238" r="0.6" fill="#878787" />
              <circle cx="266" cy="238" r="0.6" fill="#878787" />
              <circle cx="280" cy="238" r="0.6" fill="#878787" />
              <circle cx="294" cy="238" r="0.6" fill="#878787" />
              <circle cx="308" cy="238" r="0.6" fill="#878787" />
              <circle cx="322" cy="238" r="0.6" fill="#878787" />
              <circle cx="14" cy="252" r="0.6" fill="#878787" />
              <circle cx="28" cy="252" r="0.6" fill="#878787" />
              <circle cx="42" cy="252" r="0.6" fill="#878787" />
              <circle cx="56" cy="252" r="0.6" fill="#878787" />
              <circle cx="70" cy="252" r="0.6" fill="#878787" />
              <circle cx="84" cy="252" r="0.6" fill="#878787" />
              <circle cx="98" cy="252" r="0.6" fill="#878787" />
              <circle cx="112" cy="252" r="0.6" fill="#878787" />
              <circle cx="126" cy="252" r="0.6" fill="#878787" />
              <circle cx="140" cy="252" r="0.6" fill="#878787" />
              <circle cx="154" cy="252" r="0.6" fill="#878787" />
              <circle cx="168" cy="252" r="0.6" fill="#878787" />
              <circle cx="182" cy="252" r="0.6" fill="#878787" />
              <circle cx="196" cy="252" r="0.6" fill="#878787" />
              <circle cx="210" cy="252" r="0.6" fill="#878787" />
              <circle cx="224" cy="252" r="0.6" fill="#878787" />
              <circle cx="238" cy="252" r="0.6" fill="#878787" />
              <circle cx="252" cy="252" r="0.6" fill="#878787" />
              <circle cx="266" cy="252" r="0.6" fill="#878787" />
              <circle cx="280" cy="252" r="0.6" fill="#878787" />
              <circle cx="294" cy="252" r="0.6" fill="#878787" />
              <circle cx="308" cy="252" r="0.6" fill="#878787" />
              <circle cx="322" cy="252" r="0.6" fill="#878787" />
              <circle cx="14" cy="266" r="0.6" fill="#878787" />
              <circle cx="28" cy="266" r="0.6" fill="#878787" />
              <circle cx="42" cy="266" r="0.6" fill="#878787" />
              <circle cx="56" cy="266" r="0.6" fill="#878787" />
              <circle cx="70" cy="266" r="0.6" fill="#878787" />
              <circle cx="84" cy="266" r="0.6" fill="#878787" />
              <circle cx="98" cy="266" r="0.6" fill="#878787" />
              <circle cx="112" cy="266" r="0.6" fill="#878787" />
              <circle cx="126" cy="266" r="0.6" fill="#878787" />
              <circle cx="140" cy="266" r="0.6" fill="#878787" />
              <circle cx="154" cy="266" r="0.6" fill="#878787" />
              <circle cx="168" cy="266" r="0.6" fill="#878787" />
              <circle cx="182" cy="266" r="0.6" fill="#878787" />
              <circle cx="196" cy="266" r="0.6" fill="#878787" />
              <circle cx="210" cy="266" r="0.6" fill="#878787" />
              <circle cx="224" cy="266" r="0.6" fill="#878787" />
              <circle cx="238" cy="266" r="0.6" fill="#878787" />
              <circle cx="252" cy="266" r="0.6" fill="#878787" />
              <circle cx="266" cy="266" r="0.6" fill="#878787" />
              <circle cx="280" cy="266" r="0.6" fill="#878787" />
              <circle cx="294" cy="266" r="0.6" fill="#878787" />
              <circle cx="308" cy="266" r="0.6" fill="#878787" />
              <circle cx="322" cy="266" r="0.6" fill="#878787" />
              <circle cx="14" cy="280" r="0.6" fill="#878787" />
              <circle cx="28" cy="280" r="0.6" fill="#878787" />
              <circle cx="42" cy="280" r="0.6" fill="#878787" />
              <circle cx="56" cy="280" r="0.6" fill="#878787" />
              <circle cx="70" cy="280" r="0.6" fill="#878787" />
              <circle cx="84" cy="280" r="0.6" fill="#878787" />
              <circle cx="98" cy="280" r="0.6" fill="#878787" />
              <circle cx="112" cy="280" r="0.6" fill="#878787" />
              <circle cx="126" cy="280" r="0.6" fill="#878787" />
              <circle cx="140" cy="280" r="0.6" fill="#878787" />
              <circle cx="154" cy="280" r="0.6" fill="#878787" />
              <circle cx="168" cy="280" r="0.6" fill="#878787" />
              <circle cx="182" cy="280" r="0.6" fill="#878787" />
              <circle cx="196" cy="280" r="0.6" fill="#878787" />
              <circle cx="210" cy="280" r="0.6" fill="#878787" />
              <circle cx="224" cy="280" r="0.6" fill="#878787" />
              <circle cx="238" cy="280" r="0.6" fill="#878787" />
              <circle cx="252" cy="280" r="0.6" fill="#878787" />
              <circle cx="266" cy="280" r="0.6" fill="#878787" />
              <circle cx="280" cy="280" r="0.6" fill="#878787" />
              <circle cx="294" cy="280" r="0.6" fill="#878787" />
              <circle cx="308" cy="280" r="0.6" fill="#878787" />
              <circle cx="322" cy="280" r="0.6" fill="#878787" />
              <circle cx="14" cy="294" r="0.6" fill="#878787" />
              <circle cx="28" cy="294" r="0.6" fill="#878787" />
              <circle cx="42" cy="294" r="0.6" fill="#878787" />
              <circle cx="56" cy="294" r="0.6" fill="#878787" />
              <circle cx="70" cy="294" r="0.6" fill="#878787" />
              <circle cx="84" cy="294" r="0.6" fill="#878787" />
              <circle cx="98" cy="294" r="0.6" fill="#878787" />
              <circle cx="112" cy="294" r="0.6" fill="#878787" />
              <circle cx="126" cy="294" r="0.6" fill="#878787" />
              <circle cx="140" cy="294" r="0.6" fill="#878787" />
              <circle cx="154" cy="294" r="0.6" fill="#878787" />
              <circle cx="168" cy="294" r="0.6" fill="#878787" />
              <circle cx="182" cy="294" r="0.6" fill="#878787" />
              <circle cx="196" cy="294" r="0.6" fill="#878787" />
              <circle cx="210" cy="294" r="0.6" fill="#878787" />
              <circle cx="224" cy="294" r="0.6" fill="#878787" />
              <circle cx="238" cy="294" r="0.6" fill="#878787" />
              <circle cx="252" cy="294" r="0.6" fill="#878787" />
              <circle cx="266" cy="294" r="0.6" fill="#878787" />
              <circle cx="280" cy="294" r="0.6" fill="#878787" />
              <circle cx="294" cy="294" r="0.6" fill="#878787" />
              <circle cx="308" cy="294" r="0.6" fill="#878787" />
              <circle cx="322" cy="294" r="0.6" fill="#878787" />
              <circle cx="14" cy="308" r="0.6" fill="#878787" />
              <circle cx="28" cy="308" r="0.6" fill="#878787" />
              <circle cx="42" cy="308" r="0.6" fill="#878787" />
              <circle cx="56" cy="308" r="0.6" fill="#878787" />
              <circle cx="70" cy="308" r="0.6" fill="#878787" />
              <circle cx="84" cy="308" r="0.6" fill="#878787" />
              <circle cx="98" cy="308" r="0.6" fill="#878787" />
              <circle cx="112" cy="308" r="0.6" fill="#878787" />
              <circle cx="126" cy="308" r="0.6" fill="#878787" />
              <circle cx="140" cy="308" r="0.6" fill="#878787" />
              <circle cx="154" cy="308" r="0.6" fill="#878787" />
              <circle cx="168" cy="308" r="0.6" fill="#878787" />
              <circle cx="182" cy="308" r="0.6" fill="#878787" />
              <circle cx="196" cy="308" r="0.6" fill="#878787" />
              <circle cx="210" cy="308" r="0.6" fill="#878787" />
              <circle cx="224" cy="308" r="0.6" fill="#878787" />
              <circle cx="238" cy="308" r="0.6" fill="#878787" />
              <circle cx="252" cy="308" r="0.6" fill="#878787" />
              <circle cx="266" cy="308" r="0.6" fill="#878787" />
              <circle cx="280" cy="308" r="0.6" fill="#878787" />
              <circle cx="294" cy="308" r="0.6" fill="#878787" />
              <circle cx="308" cy="308" r="0.6" fill="#878787" />
              <circle cx="322" cy="308" r="0.6" fill="#878787" />
            </svg>
          </div>
          <div className={aboutStyles.aboutUsImage}>
          </div>
        </section>
        <section className={aboutStyles.founders}>
          <h2 className="primary-title">Founders</h2>
          <div className={aboutStyles.foundersCards}>
            <div>
              <img src={ben} alt="Benjamin Kane"/>
              <h3 className="secondary-title">Benjamin Kane</h3>
              <span className="tertiary-title">Co-founder & CEO</span>
              <p className="regular-text">
                Following 12 years of working in various management 
                and director roles across diverse IT companies, Benjamin 
                grew frustrated with outdated outsourcing methodologies, 
                and was determined to find a better way. In 2018, he quit 
                his role as Business Development Director and launched TechPods.
                <br /><br />
                - 12 years in the IT industry 
                <br />
                - Strong track record of building remote teams 
                <br />
                - 10 years experience in various outsourcing techniques 
                <br />
                - Digital transformation and business process specialist 
                <br /> <br />
                <strong style={{fontWeight: 800}}>Favourite Quote:</strong> <br />
                <span style={{fontSize: '0.9em', fontStyle: 'italic'}}>"If you really look closely, most overnight successes took a long time"<br />
                - Steve Jobs</span><br />
              </p>
              <hr />
              <div className={aboutStyles.founderCardContacts}>
                <a href="https://www.linkedin.com/in/kanebenjamin/" rel="noreferrer" target="_blank" title="LinkedIn">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.46687 1.21909C1.817 1.21909 0.738281 2.30247 0.738281 3.72644C0.738281 5.11896 1.78487 6.23324 3.40356 6.23324H3.43488C5.11702 6.23324 6.16388 5.11896 6.16388 3.72644C6.13243 2.30247 5.11702 1.21909 3.46687 1.21909Z" fill="#7192B1"/>
                    <path d="M1.02344 8.21384H5.84671V22.7248H1.02344V8.21384Z" fill="#7192B1"/>
                    <path d="M17.6858 7.87323C15.0838 7.87323 13.339 10.3182 13.339 10.3182V8.21381H8.51562V22.7248H13.3388V14.6213C13.3388 14.1875 13.3702 13.7544 13.4977 13.4441C13.8463 12.5779 14.6398 11.6804 15.9723 11.6804C17.7176 11.6804 18.4157 13.0111 18.4157 14.9619V22.7248H23.2385V14.4045C23.2385 9.94731 20.8589 7.87323 17.6858 7.87323Z" fill="#7192B1"/>
                  </svg>
                </a>
              </div>
            </div>
            <div>
              <img src={mike} alt="Mike Panayotov"/>
              <h3 className="secondary-title">Mike Panayotov</h3>
              <span className="tertiary-title">Co-Founder & CTO</span>
              <p className="regular-text">
                Since the start of his career in technology as a junior developer some 17 years ago, 
                Mike has applied his skills at all levels of software engineering & architecture. 
                Sharing the same values as Benjamin, Mike quit his role as CTO to disrupt the 
                IT outsourcing industry by doing what he does best - applying a modern 
                leadership mentality, by blending proven business processes with cutting-edge technology practices.
                <br /><br />
                - 17 years in the IT industry <br />
                - Strong background as Software Engineer, System Architect, CTO <br />
                - Successful background in Mobile Apps, Web, and complex back office systems <br />
                - Technology evangelist and home automation enthusiast <br /><br />
                <strong style={{fontWeight: 800}}>Favourite Quote:</strong> <br />
                <span style={{fontSize: '0.9em', fontStyle: 'italic'}}>"Everything is designed. Few things are designed well"<br />
                - Brian Reed</span><br />
              </p>
              <hr />
              <div className={aboutStyles.founderCardContacts}>
                <a href="https://www.linkedin.com/in/mpanayotov/" rel="noreferrer" target="_blank" title="LinkedIn">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.46687 1.21909C1.817 1.21909 0.738281 2.30247 0.738281 3.72644C0.738281 5.11896 1.78487 6.23324 3.40356 6.23324H3.43488C5.11702 6.23324 6.16388 5.11896 6.16388 3.72644C6.13243 2.30247 5.11702 1.21909 3.46687 1.21909Z" fill="#7192B1"/>
                  <path d="M1.02344 8.21384H5.84671V22.7248H1.02344V8.21384Z" fill="#7192B1"/>
                  <path d="M17.6858 7.87323C15.0838 7.87323 13.339 10.3182 13.339 10.3182V8.21381H8.51562V22.7248H13.3388V14.6213C13.3388 14.1875 13.3702 13.7544 13.4977 13.4441C13.8463 12.5779 14.6398 11.6804 15.9723 11.6804C17.7176 11.6804 18.4157 13.0111 18.4157 14.9619V22.7248H23.2385V14.4045C23.2385 9.94731 20.8589 7.87323 17.6858 7.87323Z" fill="#7192B1"/>
                </svg>
                </a>
              </div>
            </div>
          </div>
        </section>
        <svg className={aboutStyles.aboutSvg}  width="100%" height="779" viewBox="0 0 1170 779" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M120.004 178.495L279.582 189.11L424.512 198.751M120.004 178.495L149.442 264.301M120.004 178.495L352.608 319.96M497.503 408.082L424.512 198.751M497.503 408.082L620.748 696.132M497.503 408.082L659.938 315.229M497.503 408.082L478.787 478.497L460.071 548.913M497.503 408.082L688.665 478.059M424.512 198.751L363.737 131.357L276.795 34.9474L681.352 122.035M424.512 198.751L756.581 259.985M424.512 198.751L681.352 122.035M424.512 198.751L460.071 548.913M424.512 198.751L352.608 319.96M424.512 198.751L659.938 315.229M149.442 264.301L35.931 393.199M149.442 264.301L299.395 401.694M149.442 264.301L352.608 319.96M35.931 393.199L299.395 401.694M35.931 393.199L315.105 580.463M620.748 696.132L315.105 580.463M620.748 696.132L460.071 548.913M620.748 696.132L1097.04 588.871M620.748 696.132L688.665 478.059M352.608 319.96L299.395 401.694M352.608 319.96L460.071 548.913M299.395 401.694L315.105 580.463M299.395 401.694L460.071 548.913M756.581 259.985L1097.04 588.871M756.581 259.985L688.665 478.059M756.581 259.985L659.938 315.229M756.581 259.985L681.352 122.035M1097.04 588.871L688.665 478.059M688.665 478.059L659.938 315.229M659.938 315.229L681.352 122.035" stroke="#E0E6EB"/>
          <circle cx="150.336" cy="263.685" r="4.60455" transform="rotate(10.4481 150.336 263.685)" fill="#E0E5EB"/>
          <circle cx="299.349" cy="403.536" r="4.60455" transform="rotate(10.4481 299.349 403.536)" fill="#E0E5EB"/>
          <circle cx="351.719" cy="322.671" r="4.60455" transform="rotate(10.4481 351.719 322.671)" fill="#E0E5EB"/>
          <circle cx="423.281" cy="196.963" r="4.60455" transform="rotate(10.4481 423.281 196.963)" fill="#E0E5EB"/>
          <circle cx="682.246" cy="121.419" r="4.60455" transform="rotate(10.4481 682.246 121.419)" fill="#E0E5EB"/>
          <circle cx="755.349" cy="258.197" r="4.60455" transform="rotate(10.4481 755.349 258.197)" fill="#E0E5EB"/>
          <circle cx="660.76" cy="314.109" r="4.60455" transform="rotate(10.4481 660.76 314.109)" fill="#E0E5EB"/>
          <circle cx="620.689" cy="692.219" r="4.60455" transform="rotate(10.4481 620.689 692.219)" fill="#E0E5EB"/>
          <circle cx="689.449" cy="480.154" r="4.60455" transform="rotate(10.4481 689.449 480.154)" fill="#E0E5EB"/>
          <circle cx="1090.59" cy="590.021" r="4.60455" transform="rotate(10.4481 1090.59 590.021)" fill="#E0E5EB"/>
          <circle cx="313.734" cy="579.43" r="4.60455" transform="rotate(10.4481 313.734 579.43)" fill="#E0E5EB"/>
          <circle cx="275.146" cy="35.4236" r="4.60455" transform="rotate(10.4481 275.146 35.4236)" fill="#E0E5EB"/>
          <circle cx="459.881" cy="548.633" r="4.60455" transform="rotate(10.4481 459.881 548.633)" fill="#E0E5EB"/>
          <circle cx="495.379" cy="406.909" r="4.60455" transform="rotate(10.4481 495.379 406.909)" fill="#E0E5EB"/>
        </svg>
      </main>
    </Layout>
  );
};

export default About;